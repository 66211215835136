<template>
  <b-row>
    <b-col
      align="left"
      :class="$store.getters['layout/getInnerWidth'] < 767 ? 'img-margins' : ''"
      :cols="$store.getters['layout/getInnerWidth'] < 767 ? '4' : '3'"
    >
      <div class="product-box mt-2">
        <div class="product-img">
          <img
            class="object-fit-img"
            v-if="club.logo"
            :height="getImgDim"
            :width="getImgDim"
            :src="$filePath(club.logo.contentUrl)"
            alt
          />
          <img
            class="pointer"
            v-else
            height="140"
            width="140"
            src="@/assets/icons/playground/new/step-1/upload-image.svg"
            alt
          />
          <div class="product-hover">
            <ul
              v-if="club.logo === null"
              @click="uploadLogo(club.logo)"
            >
              <li
                class="pointer"
              >
                <button class="btn" type="button">
                  <i class="icon-upload"></i>
                </button>
              </li>
            </ul>
            <ul v-else @click="deleteLogo(club.logo)">
              <li
                class="pointer"

              >
                <button class="btn" type="button">
                  <i class="icon-trash"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </b-col>
    <b-col v-if="editMode">
      <b-row class="mt-3">
        <b-col
          :cols="getCols(2)"
          class="gray-col-title mt-1"
        >
          {{ $t('views.top-bar.settings.general-informations.complex.name') }}
        </b-col>
        <b-col class="black-col-title">
          <d-text-field
            v-model="club.name"
            :rules="validation.name"
            error-text="validation.required"
            size="sm"
            class-name="background-light-blue-inputs"
          />
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col
          :cols="getCols(2)"
          class="gray-col-title"
        >
          {{ $t('views.top-bar.settings.general-informations.complex.address') }}
        </b-col>
        <b-col
          class="black-col-title"
        >
          <d-text-field
            v-model="address"
            error-text="validation.required"
            size="sm"
            class-name="background-light-blue-inputs"
            :rules="validation.address"
          />
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col
          :cols="getCols(2)"
          class="gray-col-title"
        >
          {{ $t('views.top-bar.settings.general-informations.complex.additional-address') }}
        </b-col>
        <b-col
          class="black-col-title"
        >
          <d-text-field
            v-model="additionalAddress"
            error-text="validation.required"
            size="sm"
            class-name="background-light-blue-inputs"
          />
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col class="gray-col-title mt-1" :cols="getCols(2)">
          {{ $t('views.top-bar.settings.general-informations.complex.city') }}
        </b-col>
        <b-col :class="$store.getters['layout/getInnerWidth'] < 767 ? 'mt-1':''" class="black-col-title"
               :cols="$store.getters['layout/getInnerWidth'] < 767 ? '' : '3'">
          <d-text-field
            v-model="club.city"
            :rules="validation.city"
            error-text="validation.required"
            size="sm"
            class-name="background-light-blue-inputs"
          />
        </b-col>
        <b-col
          :class="$store.getters['layout/getInnerWidth'] < 767 ? 'mt-3':''"
          :cols="getCols(2)"
          class="gray-col-title mt-1"
        >
          {{ $t('views.top-bar.settings.general-informations.complex.zipCode') }}
        </b-col>
        <b-col
          class="black-col-title"
          :class="$store.getters['layout/getInnerWidth'] < 767 ? 'mt-1':''"
          :cols="$store.getters['layout/getInnerWidth'] < 767 ? '' : '3'"
        >
          <d-text-field
            v-model="club.zipCode"
            type="number"
            error-text="validation.required"
            size="sm"
            class-name="background-light-blue-inputs"
          />
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col class="gray-col-title mt-2" :cols="getCols(2)">
          {{ $t('views.top-bar.settings.general-informations.complex.description') }}
        </b-col>
        <b-col class="black-col-title">
          <b-textarea
            v-model="club.description"
            class="background-light-blue-inputs"
          >
          </b-textarea>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col
          :cols="getCols(2)"
          class="gray-col-title"
        >
          {{ $t('views.top-bar.settings.general-informations.complex.general-conditions') }}
        </b-col>
        <b-col
          class="black-col-title"
        >
          <d-text-field
            v-model="club.generalTermsUrl"
            error-text="validation.required"
            size="sm"
            class-name="background-light-blue-inputs"
            :rules="validation.generalTermsUrl"
          />
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col
          :cols="getCols(2)"
          class="gray-col-title"
        >
          {{ $t('views.top-bar.settings.general-informations.complex.club-rules') }}
        </b-col>
        <b-col
          class="black-col-title"
        >
          <d-text-field
            v-model="club.internalRulesUrl"
            error-text="validation.required"
            size="sm"
            class-name="background-light-blue-inputs"
            :rules="validation.internalRulesUrl"
          />
        </b-col>
      </b-row>
    </b-col>
    <b-col v-else>
      <b-row class="mt-3">
        <b-col
          :cols="getCols(2)"
          class="gray-col-title"
        >
          {{ $t('views.top-bar.settings.general-informations.complex.name') }}
        </b-col>
        <b-col class="black-col-title">
          {{ club.name }}
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col
          :cols="getCols(2)"
          class="gray-col-title"
        >
          {{ $t('views.top-bar.settings.general-informations.complex.address') }}
        </b-col>
        <b-col class="black-col-title">
          <template v-if="club.address && club.address.length > 0">
            {{ club.address[0] }}
          </template>
          <template v-else>
            {{ $t('general.actions.not-specified') }}
          </template>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col
          :cols="getCols(2)"
          class="gray-col-title"
        >
          {{ $t('views.top-bar.settings.general-informations.complex.additional-address') }}
        </b-col>
        <b-col class="black-col-title">
          <template v-if="club.address && club.address.length > 1">
            {{ club.address[1] }}
          </template>
          <template v-else>
            {{ $t('general.actions.not-specified') }}
          </template>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col class="gray-col-title" :cols="getCols(2)">
          {{ $t('views.top-bar.settings.general-informations.complex.city') }}
        </b-col>
        <b-col
          class="black-col-title"
          :class="$store.getters['layout/getInnerWidth'] < 767 ? 'mt-1' : ''"
          :cols="$store.getters['layout/getInnerWidth'] < 767 ? '' : '3'"
        >
          {{ club.city }}
        </b-col>
        <b-col
          class="gray-col-title"
          :class="$store.getters['layout/getInnerWidth'] < 767 ? 'mt-3':''"
          :cols="getCols(2)"
        >
          {{ $t('views.top-bar.settings.general-informations.complex.zipCode') }}
        </b-col>
        <b-col
          class="black-col-title"
          :class="$store.getters['layout/getInnerWidth'] < 767 ? 'mt-1':''"
          :cols="$store.getters['layout/getInnerWidth'] < 767 ? '' : '3'"
        >
          {{ club.zipCode }}
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col class="gray-col-title" :cols="getCols(2)">
          {{ $t('views.top-bar.settings.general-informations.complex.description') }}
        </b-col>
        <b-col class="black-col-title">
          {{ club.description }}
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col class="gray-col-title" :cols="getCols(2)">
          {{ $t('views.top-bar.settings.general-informations.complex.general-conditions') }}
        </b-col>
        <b-col class="black-col-title">
          <a
            href="#"
            class="link-color"
            @click="goToLink(club.generalTermsUrl)"
          >
            {{ club.generalTermsUrl }}
          </a>
        </b-col>
      </b-row>
      <b-row
        v-if="club.internalRulesUrl"
        class="mt-3"
      >
        <b-col
          class="gray-col-title"
          :cols="getCols(2)"
        >
          {{ $t('views.top-bar.settings.general-informations.complex.club-rules') }}
        </b-col>
        <b-col class="black-col-title">
          <a
            href="#"
            class="link-color"
            @click="goToLink(club.internalRulesUrl)"
          >
            {{ club.internalRulesUrl }}
          </a>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import {fromIdToIriReference, fromIriReferenceToId} from "@/utils/form";
import {deleteClubLogo} from "@api/doinsport/services/club/club-logo";

export default {
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Object,
      default: () => require('@validation/entities/doinsport/Club.json'),
    },
    club: {
      type: Object,
      default: () => {
      },
    }
  },
  data: () => ({
    address: '',
    additionalAddress: '',
  }),
  watch: {
    address: function (input) {
      this.club.address[0] = input;
    },
    additionalAddress: function (input) {
      this.club.address[1] = input;
    },
    editMode: function () {
      this.address = '';
      this.additionalAddress = '';
      this.initAddresses();
      this.initAdditionalAddresses();
    }
  },
  computed: {
    getImgDim() {
      const width = this.$store.getters["layout/getInnerWidth"];

      if (width < 767) {
        return '110';
      }
      return '145'
    },
  },
  methods: {
    goToLink(link) {
      window.open(link, '_blank');
    },
    deleteLogo(logo) {
      deleteClubLogo(logo['@id']).then((response) => {
        this.$emit('on:logo-updated');
      })
    },
    uploadLogo(logo) {
      this.$upload(
        this.uploaded,
        {
          entry: 'club',
          url: fromIdToIriReference('/clubs', this.club.id),
          target: logo === null ? 'postClubLogo' : 'putClubLogo',
          id: logo === null ? null : fromIriReferenceToId('/clubs/logos/', logo['@id'])
        }
      )
      ;
    },
    uploaded(file) {
      this.$emit('on:logo-updated');
    },
    getCols(x) {
      const width = this.$store.getters["layout/getInnerWidth"];

      if (width < 1967 && width >= 767) {
        return 3
      } else if (width < 767) {
        return 12;
      } else {
        return x;
      }
    },
    initAddresses() {
      if (this.club.address) {
        if (this.club.address.length > 0) {
          this.address = this.club.address[0];
        }
      }
    },
    initAdditionalAddresses() {
      if (this.club.address) {
        if (this.club.address.length > 1) {
          this.additionalAddress = this.club.address[1];
        }
      }
    }
  },
  mounted() {
    this.initAddresses();
    this.initAdditionalAddresses();
  }
}
</script>
<style scoped>
.img-margins {
  margin-top: 20%;
}

.product-box .product-img .product-hover {
  height: 145px;
  width: 145px;
}

.link-color {
  color: #0B2772;
}
</style>
